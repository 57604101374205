import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import './fonts/fonts.css';

export const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: "'CircularStd', sans-serif",
      h1: {
        fontWeight: 900,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '& em': {
            fontFamily: "'Libre Baskerville', serif",
          },
        },
      },
    },
  })
);
