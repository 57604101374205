import React, { FunctionComponent } from 'react';
import { Typography, Box } from '@material-ui/core';

/** Header component props */
interface HeaderProps {}

export const Header: FunctionComponent<HeaderProps> = () => {
  return (
    <Box pt={[10, 10, 20]} mb={[20, 30, 30, 40]}>
      <Typography variant="h1">Harley Alexander</Typography>
      <Typography variant="h2" gutterBottom>
        Web technologies <em>expert</em>, design <em>thinker</em>, data <em>visualiser</em>, product{' '}
        <em>builder</em>.
      </Typography>
      <Typography variant="h5" component="a" href="mailto:harley.alexander.au@gmail.com">
        Get in touch
      </Typography>
      <Typography variant="h5" component="span">
        {' / '}
      </Typography>
      <Typography variant="h5" component="a" href="https://www.linkedin.com/in/harleytalexander/">
        LinkedIn
      </Typography>
      <Typography variant="h5" component="span">
        {' / '}
      </Typography>
      <Typography variant="h5" component="a" href="https://github.com/mayteio">
        Github
      </Typography>
    </Box>
  );
};
