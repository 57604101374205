export const content = {
  workPlaces: [
    {
      title: `
        <strong>Technical & design lead</strong>,
        building a <em>physical digital data experience</em> for citizens and visitors in Melbourne Town Hall. 
      `,
      additional: ``,
      place: 'City of Melbourne',
      time: 'March 2019 - Present',
    },
    {
      title: `
        <strong>Technical director & co-founder</strong>,
        using <em>voice and chatbots</em> to make health insurance engaging again. Mentoring a talented team and <em>$1m+</em> in revenue over 3 years.
      `,
      additional: ``,
      place: 'Mayte',
      time: 'July 2015 - June 2019',
    },
    {
      title: `
        <strong>Workshop Instructor</strong>,
        <em>mentoring and teaching</em> students in design and code. Also <em>alumni</em>.
      `,
      additional: ``,
      place: 'General Assembly',
      time: 'May 2015 - Present',
    },
    {
      title: `
        <strong>Lead designer & developer</strong>,
        Helping disruptive startups <em>grow rapidly</em>.
      `,
      additional: ``,
      place: 'Marketing Partners',
      time: 'April 2013 - July 2015',
    },
    {
      title: `
        <strong>WordPress weapon & digital designer</strong>,
        Bringing a <em>cohesive brand</em> and a <em>common platform</em> to a family of educational websites
      `,
      additional: '',
      place: 'Sitepoint',
      time: 'October 2011 - February 2013',
    },
    {
      title: `
        <strong>Author</strong>,
        Co-authoring a <em>WordPress rockstar bible</em> with founder Collis Ta'eed.
      `,
      place: 'Envato',
      time: '2007',
    },
    {
      title: `
        <strong>Freelance designer & developer</strong>,
        <em>Building and breaking</em> things since high-school.
      `,
      place: 'Wherever I call home',
      time: '2005 - present',
    },
  ],
};
