import React, { FunctionComponent, useEffect } from 'react';
import { useTrail, animated } from 'react-spring';
import { Box } from '@material-ui/core';
import './goo.css';

/** Goo component props */
interface GooProps {
  xy: number[];
}

const fast = { tension: 500, friction: 100 };
const slow = { mass: 10, tension: 300, friction: 75 };
const trans = (x: number, y: number) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;

export const Goo: FunctionComponent<GooProps> = ({ xy: [x, y] }) => {
  const [trail, set] = useTrail(4, () => ({
    xy: [0, 0],
    config: (i: number) => (i === 0 ? fast : slow),
  }));

  /** Listen for changes in XY */
  useEffect(() => {
    set({ xy: [x, y] });
  }, [x, y, set]);

  return (
    <Box position="fixed" width={1} height={1}>
      <svg style={{ position: 'fixed', width: 0, height: 0 }}>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="30" />
          <feColorMatrix in="blur" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 30 -7" />
        </filter>
      </svg>
      <div className="hooks-main">
        {trail.map((props, index) => (
          <animated.div key={index} style={{ transform: props.xy.interpolate(trans as any) }} />
        ))}
      </div>
    </Box>
  );
};
