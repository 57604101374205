import React, { FunctionComponent } from 'react';
import { Typography, Box } from '@material-ui/core';
import { content } from './content';

/** Work component props */
interface WorkProps {}

export const Work: FunctionComponent<WorkProps> = () => {
  return (
    <>
      {content.workPlaces.map((place, i) => (
        <Box mb={20} key={i}>
          <Typography
            variant="h3"
            component="p"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: place.title }}
          />
          <Typography variant="h5" component="p">
            {place.place}, {place.time}
          </Typography>
        </Box>
      ))}
    </>
  );
};
