import React, { useState } from 'react';
import { Header } from './common/Header';
import { Work } from './common/Work';
import { makeStyles, Theme, Box } from '@material-ui/core';
import { Goo } from './common/Goo';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& em': {
      fontFamily: "'Libre Baskerville', serif",
    },
    '& a': {
      color: '#000',
      textDecoration: 'none',
      borderBottom: '3px solid #000',
      transition: theme.transitions.create(['color', 'border-bottom']),
      '&:hover': {
        color: '#fff',
        borderBottom: '3px solid #fff',
      },
    },
  },
}));

function App() {
  /** Get scoped CSS classes */
  const classes = useStyles();
  const [xy, set] = useState([150, 150]);
  return (
    <>
      <Goo xy={xy} />
      <Box p={2} className={classes.root} onMouseMove={(e) => set([e.clientX, e.clientY])}>
        <Box position="relative" zIndex={9}>
          <Header />
          <Work />
        </Box>
      </Box>
    </>
  );
}

export default App;
